import * as React from "react"
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpen, faMapMarker } from '@fortawesome/free-solid-svg-icons'

import Container from "./container"
import AnimationWrapper from "./animationWrapper"
import InnerSection from "./innerSection"
import ContactForm from "./contactForm"
import ButtonExternal from "./buttonExternal"
import Title from "./title"

import "./contactSection.css"

const ContactSection = ( { data, location } ) => {

  return (
    <div className={`${location}-contact contact-section`}>
      <InnerSection className="contact-data">

        {/* {data.image && */}
        {data.type === 'image' &&
          <AnimationWrapper>
            <GatsbyImage
              className="image-container"
              objectFit="cover"
              image={data.image.childImageSharp.gatsbyImageData}
              alt={data.title}
            />
          </AnimationWrapper>
        }

        {data.type === 'contact-data' &&
          <div className="contact-data-container">
            <AnimationWrapper>
                <StaticImage
                  className="contact-logo"
                  src="../images/logo-footer.png"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="GTIT - Napędzamy Twój biznes"
                />

                <div className="section-description-medium bold">GTIT sp. z o.o.</div>

                <ul className="contact-info-wrapper">
                  <li><FontAwesomeIcon icon={faMapMarker} /><a href="#location-map-pin">ul. K. Tymienieckiego 8</a></li>
                  <li><FontAwesomeIcon icon={faEnvelopeOpen} /><a href="mailto:biuro@gt-it.pl">biuro@gt-it.pl</a></li>
                  <li><FontAwesomeIcon icon={faPhone} /><a href="tel:+48616661029">+48 61 666 10 29</a></li>
                </ul>

                <div className="section-description-medium bold">Dane rejestrowe</div>

                <div className="section-description-small">
                  ul. Bułgarska 78, lok.<br />
                  760-321 Poznań<br />
                  <br />
                  NIP PL7792499963<br />
                  REGON 381872644<br />
                  KRS 0000759370
                </div>
            </AnimationWrapper>
          </div>
        }

        {data.type === 'serwis-data' &&
          <div className="contact-data-container serwis-data align-left">
            <AnimationWrapper>

                <Title title="Kontakt ws. technicznych" />

                <ul className="contact-info-wrapper">
                  <li><FontAwesomeIcon icon={faEnvelopeOpen} /><a href="mailto:serwis@gt-it.pl">serwis@gt-it.pl</a></li>
                  <li><FontAwesomeIcon icon={faPhone} /><a href="tel:+48616661029">+48 61 666 10 29</a></li>
                </ul>

                <div className="section-description bold">TeamViewer dla komputerów z systemem Windows</div>
                <ButtonExternal btnStyle="color-bright" btnLink="https://download.teamviewer.com/download/TeamViewer_Setup_x64.exe" btnText="Pobierz od producenta" />

                <div className="section-description bold">TeamViewer dla komputerów z systemem macOS</div>
                <ButtonExternal btnStyle="color-bright" btnLink="https://download.teamviewer.com/download/TeamViewer.dmg" btnText="Pobierz od producenta" />

                <StaticImage
                  className="contact-logo"
                  src="../images/logo-footer.png"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="GTIT - Napędzamy Twój biznes"
                />

            </AnimationWrapper>
          </div>
        }

        <div className="contact-form-container">
          <AnimationWrapper>
            {data.title &&
              <h2 className="section-heading">{data.title}</h2>
            }
            {data.description &&
              <h2 className="section-description">{data.description}</h2>
            }
            <ContactForm title="GT-IT" fields={data.fields} />
          </AnimationWrapper>
        </div>

      </InnerSection>
    </div>
  )
}

export default ContactSection