import * as React from "react"

import "./textSection.css"

const Title = ({ className, title, subtitle }) => {
    return (
      <div className="title-container">
          {subtitle &&
            <span class="section-subtitle">{subtitle}</span>
          }
          <h2 className={`section-title ${className}`}>
            {title}
          </h2>
      </div>
    )
  }

export default Title