import * as React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import Title from "./title"
import Container from "./container"
import AnimationWrapper from "./animationWrapper"
import Button from "./button"

import "./textSection.css"

const TextSection = ({ className, data }) => {

  return (
    <div className={`page-section text-section ${className}`}>
      {data.title_visible &&

        <Title className={`${data.type} ${data.subtitle ? "has-subtitle" : ""}` } title={data.title} subtitle={data.subtitle}>
          {data.icon &&
            <div className="image-container">
              <GatsbyImage image={data.icon.childImageSharp.gatsbyImageData} />
            </div>
          }
        </Title>
      }
      {data.text &&
        <p className="section-description" dangerouslySetInnerHTML={{ __html: data.text }}/>
      }
      {/* {data.image &&
        <div>
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            alt={data.image_alt}
          />
        </div>
      } */}
      {data.button_text &&
        <Button btnStyle="color-dark" btnLink={data.button_url} btnText={data.button_text}/>
      }
    </div>
  )
}

export default TextSection