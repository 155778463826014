import * as React from "react"
// import PropTypes from "prop-types"

import "./innerSection.css"

const InnerSection = ({ className, children }) => {
    return (
        <div className={`${className} inner-section`}>
            {children}
        </div>
    )
  }

export default InnerSection