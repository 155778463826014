import React, { useState, useEffect } from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from "./container"
import "./imageGridSection.css"
import "./button.css"

const ImageGridSection = ( { data, location, imagesCount, btnText } ) => {

  const [initialData, setInitialData] = useState([]);
  const [extendedData, setExtendedData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  // Function to divide data into initialData and extendedData
  const divideData = () => {
    if (data.length > imagesCount) {
      setInitialData(data.slice(0, imagesCount));
      setExtendedData(data.slice(imagesCount));
    } else {
      setShowAll(true);
      setInitialData(data);
    }
  };

  useEffect(() => {
    divideData();
  }, [data, imagesCount]);

    return (
      <Container className={`${location}-grid image-grid-container`}>
        {initialData.map((image) => (
          <div className="single-image-wrapper show">

            <GatsbyImage
              className="image-container"
              objectFit="contain"
              image={image.image.childImageSharp.gatsbyImageData}
              alt={image.title}
            />

            {image.description &&
              <h4 className="image-desc">
                {image.description}
              </h4>
            }

          </div>
        ))}

        <div className={`showall-btn-container ${showAll ? "" : "show"}`}>
          <button className={`cta-btn btn-color-dark`} onClick={() => setShowAll(true)}>
            <a>{btnText}</a>
          </button>
        </div>

        {extendedData &&
          <>
            {extendedData.map((image) => (
              <div className={`single-image-wrapper ${showAll ? "show" : ""}`}>

                <GatsbyImage
                  className="image-container"
                  objectFit="contain"
                  image={image.image.childImageSharp.gatsbyImageData}
                  alt={image.title}
                />

                {image.description &&
                  <h4 className="image-desc">
                    {image.description}
                  </h4>
                }

              </div>
            ))}
          </>
        }

      </Container>
    )
  }

export default ImageGridSection